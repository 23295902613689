@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
@import "./assets/styles/main.scss";
@import "mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}

.main-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@media (min-width: 480.1px) {
  .main-container {
    max-width: 1410px;
    gap: 5rem;
  }
}

a {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
}

h1,
h2,
p {
  margin: 0 !important;
  font-family: "Montserrat" !important;
  font-style: normal;
}

// main titles styles
section {
  .title {
    display: flex;
    align-items: center;
    gap: 1rem;
    .highlighter {
      width: 15px;
      height: 32px;
    }
    h1 {
      margin: 0;
      font-family: Montserrat, sans-serif;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
    }
  }
}
@media (min-width: 480.1px) {
  section {
    .title {
      display: flex;
      align-items: center;
      gap: 1rem;
      .highlighter {
        width: 15px;
        height: 36px;
      }
      h1 {
        font-weight: 600;
        font-size: 48px;
      }
    }
  }
}
/*
  CAROUSEL STYLES
*/
.cards-menu-carousel {
  position: relative;
  max-width: 1410px;
}

::ng-deep {
  .p-carousel {
    margin-top: 24px !important;
  }
}

.p-carousel-items-container {
  gap: 2rem;
}
#pr_id_4 .p-carousel-item {
  flex: 1 0 100%;
}

// #pr_id_1 > div > div > button.p-ripple.p-carousel-next.p-link {
//   background-color: #f7f7f7;
//   color: #000;
//   position: absolute;
//   bottom: 82%;
//   right: 0;
//   &:hover {
//     background-color: #008d6d;
//     color: white;
//   }
// }
// #pr_id_1 > div > div > button.p-ripple.p-carousel-prev.p-link {
//   background-color: #f7f7f7;
//   color: black;
//   position: absolute;
//   bottom: 82%;
//   right: 65px;

//   &:hover {
//     background-color: #008d6d;
//     color: white;
//   }
// }

#pr_id_2 > div > div > button.p-ripple.p-carousel-next.p-link {
  background-color: #f7f7f7;
  color: black;
  position: absolute;
  bottom: 82%;
  // bottom: 324px;
  right: 0;

  &:hover {
    background-color: #008d6d;
    color: white;
  }
}

#pr_id_2 > div > div > button.p-ripple.p-carousel-prev.p-link {
  background-color: #f7f7f7;
  color: black;
  position: absolute;
  bottom: 82%;
  // bottom: 324px;
  right: 65px;

  &:hover {
    background-color: #008d6d;
    color: white;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

.input-mask {
  padding: 1rem;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #000000 !important;
  border-radius: 5px;
}

.p-inputtext:enabled:focus {
  outline: 1px solid black !important;
  outline-offset: 0;
  box-shadow: none !important;
  border-color: #2196f3;
}

// body
//   > app-root
//   > app-user-profile
//   > div
//   > app-services
//   > div
//   > form
//   > div.right-side
//   > div
//   > p-fileupload
//   > div
//   > span {
//   background: none;
//   border: none;
// }

// .p-fileupload-choose:not(.p-disabled):hover {
//   background: none;
// }

.p-fileupload-choose:not(.p-disabled):hover {
  background: none !important;
  color: none !important;
  border: none !important;
}

.p-fileupload {
  .p-button {
    background: none !important;
    border: none !important;
  }
}

// Add file to upload icon style
body
  > app-root
  > app-user-profile
  > div
  > app-services
  > div
  > form
  > div.right-side
  > div
  > p-fileupload
  > div
  > span
  > span.p-button-icon.p-button-icon-left.pi.pi-plus {
  color: #00888e;
  font-size: 38px;
  font-weight: 600;
}

// Upload file icon style
body
  > app-root
  > app-user-profile
  > div
  > app-services
  > div
  > form
  > div.right-side
  > div
  > p-fileupload
  > div
  > span
  > span.p-button-icon.p-button-icon-left.pi.pi-upload {
  color: #00888e;
  font-size: 38px;
  font-weight: 600;
}
